import { DownOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Tooltip, message } from "antd";
import { useState } from "react";
import ReservationsReportModal from "./ReservationsReportModal";
import UpdateApartmentParameterModal from "./UpdateApartmentParameterModal";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { compareAsc, format } from "date-fns";
import { taskPromise } from "common/task";
import { cache } from "apollo";
import { saveAs } from 'file-saver';
import { makeSlug } from "common/common";
import JSZip from "jszip";
import { generateExcelFile } from "common/excel";


const GET_RESERVATIONS_QUERY = gql`
    query GetReservationsForReport($apartmentId: ID!, $dateFrom: Date!, $dateTo: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            reservations(filter: {status: "confirmed", endDateGte: $dateFrom, endDateLte: $dateTo}) {
                id
                airbnbConfirmationCode
                startDate
                endDate
                numberOfGuests
                priceAccommodation
                priceCleaning
                priceCommission
                otaName
            }
        }
    }
`;

const UPDATE_APARTMENT_MUTATION = gql`
    mutation UpdateApartment($input: UpdateApartmentInput!) {
        updateApartment(input: $input) {
            error {
                type
                message
            }
            syncApartmentUpTask {
                id
            }
            recalculateApartmentCalendarTask {
                id
            }
            syncApartmentCalendarUpTask {
                id
            }
        }
    }
`;

export default function ApartmentActions(props) {
    const {
        selectedApartments,
    } = props;

    const [reservationsReportModalOpen, setReservationsReportModalOpen] = useState(false);
    const [reservationsReportModalLoading, setReservationsReportModalLoading] = useState(false);
    const [updateApartmentParameterModalOpen, setUpdateApartmentParameterModalOpen] = useState(false);
    const [updateApartmentParameterModalLoading, setUpdateApartmentParameterModalLoading] = useState(false);

    const [getReservations] = useLazyQuery(GET_RESERVATIONS_QUERY);
    const [updateApartment] = useMutation(UPDATE_APARTMENT_MUTATION);

    if (!selectedApartments || selectedApartments.length === 0) {
        return (
            <Tooltip
                title="Select apartments in order to perform actions"
            >
                <Button
                    disabled
                    icon={<DownOutlined />}
                >
                    Group actions
                </Button>
            </Tooltip>
        );
    }

    function handleAction(action) {
        if (action === 'reservationsReport') {
            setReservationsReportModalOpen(true);
        }
        if (action === 'updateApartmentParameter') {
            setUpdateApartmentParameterModalOpen(true);
        }
    }

    function handleReservationsReport(values) {
        setReservationsReportModalLoading(true);
        Promise.all(
            selectedApartments.map(apartmentId => {
                return getReservations({
                    variables: {
                        apartmentId,
                        dateFrom: values.dateFrom,
                        dateTo: values.dateTo,
                    },
                    fetchPolicy: 'network-only',
                })
                    .then(response => response.data.apartment);
            })
        )
            .then(reportData => {
                const zip = new JSZip();
                const folder = zip.folder(`Reservations ${format(values.dateFrom, 'yyyy-MM')}`);

                Promise.all(
                    reportData.map(apartment => {
                        return generateExcelFile({
                            columns: [
                                {
                                    label: 'Confirmation code',
                                    key: 'airbnbConfirmationCode',
                                    width: 20,
                                },
                                {
                                    label: 'Start date',
                                    key: 'startDate',
                                    date: true,
                                },
                                {
                                    label: 'End date',
                                    key: 'endDate',
                                    date: true,
                                },
                                {
                                    label: 'OTA',
                                    key: 'otaName',
                                },
                                {
                                    label: 'Guests',
                                    key: 'numberOfGuests',
                                },
                                {
                                    label: 'Accommodation',
                                    key: 'priceAccommodation',
                                    number: true,
                                    width: 17,
                                },
                                {
                                    label: 'Cleaning',
                                    key: 'priceCleaning',
                                    number: true,
                                    width: 17,
                                },
                                {
                                    label: 'Commission',
                                    key: 'priceCommission',
                                    number: true,
                                    width: 17,
                                },
                            ],
                            data:
                                [...apartment.reservations ?? []]
                                    .sort((a, b) => compareAsc(a.startDate, b.startDate)),
                            sheetName: `Reservations`,
                        })
                            .then(data => {
                                return Promise.resolve({
                                    apartmentName: apartment.name,
                                    data,
                                })
                            })
                    })
                )
                    .then(files => {
                        files.forEach(({ apartmentName, data }) => {
                            folder.file(`${makeSlug(apartmentName)}.xlsx`, data, { binary: true });
                        });

                        zip.generateAsync({ type: 'blob' })
                            .then(file => {
                                saveAs(file, `reservations-${format(values.dateFrom, 'yyyy-MM')}.zip`);
                            });
                    });
            })
            .finally(() => {
                setReservationsReportModalLoading(false);
                setReservationsReportModalOpen(false);
            })
    }

    function handleUpdateApartmentParameter(values) {
        setUpdateApartmentParameterModalLoading(true);
        Promise.all(
            selectedApartments.map(apartmentId => {
                return updateApartment({
                    variables: {
                        input: {
                            apartmentId,
                            [values.parameter]: values.value,
                        }
                    }
                })
                    .then(response => {
                        return Promise.all([
                            taskPromise(response.data.updateApartment.syncApartmentUpTask?.id),
                            taskPromise(response.data.updateApartment.recalculateApartmentCalendarTask?.id),
                            taskPromise(response.data.updateApartment.syncApartmentCalendarUpTask?.id),
                        ]);
                    })
            })
        )
            .then(() => {
                cache.evict({
                    id: 'ROOT_QUERY',
                    fieldName: 'apartments',
                });
                message.success('Parameters updated');
            })
            .finally(() => {
                setUpdateApartmentParameterModalLoading(false);
                setUpdateApartmentParameterModalOpen(false);
            })
    }

    return (
        <>
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'Reservations report',
                            key: 'reservationsReport',
                            icon: <DownloadOutlined />,
                        },
                        {
                            label: 'Update parameter',
                            key: 'updateApartmentParameter',
                            icon: <EditOutlined />,
                        },
                    ],
                    onClick: ({ key: action }) => handleAction(action),
                }}
            >
                <Button
                    icon={<DownOutlined />}
                >
                    Group actions
                </Button>
            </Dropdown>
            <ReservationsReportModal
                open={reservationsReportModalOpen}
                loading={reservationsReportModalLoading}
                onClose={() => setReservationsReportModalOpen(false)}
                onSubmit={values => handleReservationsReport(values)}
            />
            <UpdateApartmentParameterModal
                open={updateApartmentParameterModalOpen}
                loading={updateApartmentParameterModalLoading}
                onClose={() => setUpdateApartmentParameterModalOpen(false)}
                onSubmit={values => handleUpdateApartmentParameter(values)}
            />
        </>
    );
}